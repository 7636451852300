import React from 'react'
// import { Link } from "gatsby"

import ApplySingle from '../components/apply/single'
import Header from '../components/header'
import IntroAlt from '../components/sections/intro/alt'
import Layout from '../components/layout'
import HomeContent from '../components/sections/home-content'
import Partners from '../components/sections/partners'
import SEO from '../components/seo'
import Zip from '../components/apply/zip'

import elderly from '../images/elderly-hiking.png'

const AltPage = ({ location }) => (
  <Layout phone="877-755-6327" location={location}>
    <SEO
      title="Life Insurance Offers"
      keywords={[`life`, `insurance`, `plans`, `affordable`]}
    />
    <Header phone="877-755-6327" />
    <IntroAlt
      imgTitle="Protect what matters, when it matters most."
      imgText="Life gets complicated, but insuring it shouldn’t be. With Life Insurance Companion we’re there with you every step of the way to find the best policy for you. Every person is unique and we’ll help you find coverage that works with your life."
      title="Just answer a few questions for your <span>free</span> life insurance quote."
      subTitle="Getting a quote is 100% free, fast, and secure."
      colType="right-col"
      bgImage={elderly}
    >
      <div className="form-box">
        <div className="contacts-form alt text-center">
          <Zip shouldNavigate={true} />
        </div>
      </div>
    </IntroAlt>
    <main id="main">
      <Partners />
      <HomeContent />
    </main>
  </Layout>
)

export default AltPage
